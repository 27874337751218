import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const VerticalNav = ({ currentMode = 'current' }) => {
  const [activeSection, setActiveSection] = useState('intro');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { id: 'intro', label: 'INTRO' },
    { id: 'about', label: 'ABOUT' },
    { id: 'skills', label: 'SKILLS' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      menuItems.forEach(({ id }) => {
        const element = document.getElementById(id);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            setActiveSection(id);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getStyles = () => {
    switch (currentMode) {
      case 'light':
        return {
          dot: 'border-gray-400',
          activeDot: 'bg-gray-900',
          text: 'text-gray-900',
          line: 'bg-gray-200',
          menu: 'bg-white/80 border-gray-200',
          menuText: 'text-gray-900',
          overlay: 'bg-gray-500/10'
        };
      case 'hacker':
        return {
          dot: 'border-green-500',
          activeDot: 'bg-green-500',
          text: 'text-green-500',
          line: 'bg-green-500/20',
          menu: 'bg-black/80 border-green-500/30',
          menuText: 'text-green-500',
          overlay: 'bg-green-900/10'
        };
      default:
        return {
          dot: 'border-white',
          activeDot: 'bg-white',
          text: 'text-white',
          line: 'bg-white/20',
          menu: 'bg-black/80 border-white/20',
          menuText: 'text-white',
          overlay: 'bg-black/60'
        };
    }
  };

  const styles = getStyles();

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="fixed right-8 top-1/2 -translate-y-1/2 z-40">
        <div className="relative">
          <div className={`absolute right-[5px] top-0 bottom-0 w-px ${styles.line}`} />

          <div className="space-y-8">
            {menuItems.map(({ id, label }) => (
              <motion.div
                key={id}
                className="relative flex items-center cursor-pointer group"
                onClick={() => scrollToSection(id)}
                whileHover="hover"
              >
                <motion.span
                  className={`absolute right-8 text-sm font-['Helvetica Now Display'] font-bold opacity-0 group-hover:opacity-100 transition-opacity ${styles.text}`}
                  variants={{
                    hover: { x: 0, opacity: 1 }
                  }}
                >
                  {label}
                </motion.span>
                
                <div className="relative w-3 h-3">
                  <div className={`absolute inset-0 rounded-full border ${styles.dot}`} />
                  {activeSection === id && (
                    <motion.div
                      layoutId="activeDot"
                      className={`absolute inset-0 rounded-full ${styles.activeDot}`}
                      transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    />
                  )}
                </div>
              </motion.div>
            ))}
          </div>

          <div 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="absolute -bottom-16 right-[2px] cursor-pointer group"
          >
            <div className="relative w-3 h-3">
              <div className={`absolute inset-0 rounded-full border ${styles.dot}`} />
            </div>
            <motion.span
              className={`absolute -bottom-4 left-1/2 -translate-x-1/2 text-[10px] font-['Helvetica Now Display'] font-bold tracking-wider whitespace-nowrap ${styles.text}`}
              animate={{ opacity: [0.5, 1, 0.5] }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              MENU
            </motion.span>
          </div>
        </div>
      </nav>

      <AnimatePresence>
        {isMenuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`fixed inset-0 backdrop-blur-sm z-40 ${styles.overlay}`}
              onClick={() => setIsMenuOpen(false)}
            />
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: "tween", duration: 0.3 }}
              className={`fixed right-8 bottom-8 w-80 backdrop-blur-xl z-50 border rounded-xl p-8 ${styles.menu}`}
            >
              <div className="flex justify-between items-center mb-8">
                <h2 className={`font-['Helvetica Now Display'] font-bold ${styles.menuText}`}>Navigation</h2>
                <button 
                  onClick={() => setIsMenuOpen(false)}
                  className={`${styles.menuText} opacity-70 hover:opacity-100`}
                >
                  ✕
                </button>
              </div>
              
              <div className="space-y-6">
                {menuItems.map(({ id, label }) => (
                  <motion.div
                    key={id}
                    className={`${styles.menuText} opacity-70 hover:opacity-100 cursor-pointer font-['Helvetica Now Display'] font-bold`}
                    whileHover={{ x: 10 }}
                    onClick={() => scrollToSection(id)}
                  >
                    {label}
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default VerticalNav;