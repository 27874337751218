import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TeslaTypingAnimation = ({ mode = 'current' }) => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const period = 2000;

  const messages = [
    "Hi there, thanks for visiting my page. I'm Oluwaseyi Montclair and I'm an IT Security/Cyber Analyst. Feel free to explore"
  ];

  const tick = useCallback(() => {
    const i = loopNum % messages.length;
    const fullText = messages[i];

    if (isDeleting) {
      setText(prev => prev.substring(0, prev.length - 1));
    } else {
      setText(prev => fullText.substring(0, prev.length + 1));
    }

    if (!isDeleting && text === fullText) {
      setTimeout(() => setIsDeleting(true), period);
    } else if (isDeleting && text === '') {
      setIsDeleting(false);
      setLoopNum(prev => prev + 1);
    }
  }, [isDeleting, loopNum, text, messages]);

  useEffect(() => {
    const ticker = setInterval(tick, 50);
    return () => clearInterval(ticker);
  }, [tick]);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsVisible(v => !v);
    }, 800);
    return () => clearInterval(timer);
  }, []);

  const getStyles = () => {
    switch (mode) {
      case 'light':
        return {
          text: 'text-gray-900',
          cursor: 'bg-gray-900',
          background: 'bg-white/80',
          container: 'border-gray-300',
          shadow: 'shadow-lg shadow-gray-200/50'
        };
      case 'hacker':
        return {
          text: 'text-green-500',
          cursor: 'bg-green-500',
          background: 'bg-black/60',
          container: 'border-green-500/30',
          shadow: 'shadow-lg shadow-green-500/10'
        };
      default:
        return {
          text: 'text-white',
          cursor: 'bg-white',
          background: 'bg-black/60',
          container: 'border-white/20',
          shadow: 'shadow-lg shadow-blue-500/10'
        };
    }
  };

  const styles = getStyles();

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
        className={`${styles.background} backdrop-blur-xl rounded-xl border ${styles.container} ${styles.shadow}`}
        style={{
          boxSizing: 'border-box',
          borderWidth: '1px',
        }}
      >
        <div className="max-w-6xl mx-auto px-8 py-16">
          <div className="flex flex-col space-y-6">
            <motion.div 
              className={`font-['Helvetica Now Display'] font-bold text-2xl md:text-4xl lg:text-5xl leading-relaxed ${styles.text}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {text}
              <span 
                className={`inline-block w-0.5 h-8 md:h-12 ml-1 transition-opacity duration-200 ${styles.cursor}`}
                style={{ opacity: isVisible ? 1 : 0 }}
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TeslaTypingAnimation;