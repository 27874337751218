import React, { useState, useEffect } from 'react';

const LoadingPage = () => {
  const [progress, setProgress] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  // Progress increment effect - Fixed version
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures it only runs once

  // Mouse movement handler
  useEffect(() => {
    const handleMouseMove = (e) => {
      const xAxis = (window.innerWidth / 2 - e.pageX) / 40;
      const yAxis = (window.innerHeight / 2 - e.pageY) / 40;
      setMousePosition({ x: xAxis, y: yAxis });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div 
      className="flex flex-col justify-center h-screen p-8 relative overflow-hidden"
      style={{ 
        background: 'linear-gradient(to bottom, #000000, #1a1a1a, #000000)',
        perspective: '1000px'
      }}
    >
      {/* Rotating Grid Background */}
      <div className="fixed inset-0 animate-spin-slow">
        <div 
          className="absolute w-[200vw] h-[200vh] -left-[50vw] -top-[50vh]"
          style={{
            backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0.1) 1px, transparent 1px), linear-gradient(rgba(255,255,255,0.1) 1px, transparent 1px)',
            backgroundSize: '40px 40px',
            transform: 'rotateX(60deg)'
          }}
        />
      </div>

      {/* Holographic Effect */}
      <div 
        className="absolute left-1/2 top-1/2 w-[400px] h-[400px] pointer-events-none animate-pulse"
        style={{
          transform: 'translate(-50%, -50%) rotateX(90deg)',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, transparent 70%)'
        }}
      />

      {/* Loading Container */}
      <div 
        className="max-w-xl w-full mx-auto relative z-10"
        style={{
          transform: `rotateY(${mousePosition.x}deg) rotateX(${mousePosition.y}deg)`,
          transition: 'transform 0.1s ease-out',
          transformStyle: 'preserve-3d'
        }}
      >
        <div className="flex flex-col items-center gap-12">
          {/* Loading Bar */}
          <div 
            className="w-[300px] h-[2px] relative overflow-hidden"
            style={{
              background: 'rgba(255,255,255,0.1)',
              transform: 'translateZ(20px)',
              boxShadow: '0 0 20px rgba(255,255,255,0.2)'
            }}
          >
            <div 
              className="absolute left-0 h-full bg-white transition-[width]"
              style={{
                width: `${progress}%`,
                boxShadow: '0 0 30px white'
              }}
            />
          </div>

          {/* Loading Text */}
          <div 
            className="text-white text-base font-light uppercase tracking-[0.5em]"
            style={{
              transform: 'translateZ(40px)',
              textShadow: '0 0 20px rgba(255,255,255,0.5)'
            }}
          >
            Initializing
          </div>

          {/* Percentage */}
          <div 
            className="text-white text-5xl font-extralight relative"
            style={{
              transform: 'translateZ(60px)',
              filter: 'drop-shadow(0 0 5px white)'
            }}
          >
            {progress}%
            <div 
              className="absolute -bottom-5 left-1/2 w-[120%] h-[1px] bg-white"
              style={{
                transform: 'translateX(-50%)',
                boxShadow: '0 0 20px white',
                opacity: 0.5
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;

// Add this to your global CSS or style tag
const styles = `
  @keyframes spin-slow {
    from { transform: translateZ(-50px) rotateZ(0deg); }
    to { transform: translateZ(-50px) rotateZ(360deg); }
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 0.3;
      transform: translate(-50%, -50%) rotateX(90deg) scale(1);
    }
    50% {
      opacity: 0.6;
      transform: translate(-50%, -50%) rotateX(90deg) scale(1.2);
    }
  }
  
  .animate-spin-slow {
    animation: spin-slow 20s linear infinite;
  }
  
  .animate-pulse {
    animation: pulse 4s ease-in-out infinite;
  }
`;