import React, { useState, useEffect } from 'react';

const GlitchText = ({ text, isHovered }) => {
    const [displayText, setDisplayText] = useState(text);
    const glitchCharacters = '!<>-_\\/[]{}—=+*^?#________';
  
    useEffect(() => {
      if (!isHovered) {
        setDisplayText(text);
        return;
      }
  
      let iteration = 0;
      const maxIterations = 8;
      const interval = 50;
  
      const glitchInterval = setInterval(() => {
        setDisplayText(prev => {
          if (iteration >= maxIterations) {
            clearInterval(glitchInterval);
            return text;
          }
  
          iteration += 1/3;
  
          return text
            .split("")
            .map((char, idx) => {
              if (idx < iteration) return text[idx];
              if (char === " ") return char;
              return glitchCharacters[Math.floor(Math.random() * glitchCharacters.length)];
            })
            .join("");
        });
      }, interval);
  
      return () => clearInterval(glitchInterval);
    }, [isHovered, text]);
  
    return (
      <span className="font-mono tracking-wide">
        {displayText}
      </span>
    );
  };


export default GlitchText;