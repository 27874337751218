import React, { useState, useEffect } from 'react';
import LoadingPage from './LoadingPage';
import LandingPage from './LandingPage';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // Adjust time as necessary for your loading screen

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoading ? <LoadingPage /> : <LandingPage />}
    </div>
  );
}

export default App;





