import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ShieldCheck, LockKeyhole, Terminal, Network, 
  Wrench, Code, Database, FileCode, Monitor
} from 'lucide-react';
import TypingAnimation from './TypingAnimation';
import PowerToggle from './PowerToggle';
import NavMenu from './NavMenu';
import SkillCard from './SkillCard';
import VerticalNav from './VerticalNav';

const BinaryRain = () => (
  <div className="fixed inset-0 pointer-events-none overflow-hidden">
    {Array.from({ length: 20 }).map((_, i) => (
      <div
        key={i}
        className="absolute top-0 text-green-500/30 text-sm font-mono whitespace-nowrap animate-matrix-rain"
        style={{
          left: `${(i / 20) * 100}%`,
          animationDelay: `${Math.random() * 2}s`,
        }}
      >
        {Array.from({ length: 25 }).map((_, j) => (
          <div key={j} className="my-1" style={{ opacity: Math.random() }}>
            {Math.random() > 0.5 ? '1' : '0'}
          </div>
        ))}
      </div>
    ))}
  </div>
);

const DecorativeLines = ({ currentMode }) => (
    <div className="absolute inset-0 pointer-events-none overflow-hidden">
      {[1, 2, 3].map((i) => (
        <React.Fragment key={i}>
          <motion.div
            className={`absolute left-0 w-full h-px ${
              currentMode === 'hacker' ? 'bg-green-500/20' : 'bg-white/20'
            }`}
            style={{ top: `${i * 25}%` }}
          />
          <motion.div
            className={`absolute top-0 h-full w-px ${
              currentMode === 'hacker' ? 'bg-green-500/20' : 'bg-white/20'
            }`}
            style={{ left: `${i * 25}%` }}
          />
        </React.Fragment>
      ))}
  
      {[1, 2, 3].map((i) => (
        <motion.div
          key={`glow-${i}`}
          className={`absolute h-px w-full ${
            currentMode === 'hacker' ? 'bg-green-400' : 'bg-white'
          }`}
          style={{ top: `${i * 25}%` }}
          animate={{
            x: ['-100%', '100%'],
            opacity: [0.1, 0.3, 0.1]
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "linear",
            delay: i
          }}
        />
      ))}
    </div>
  );

const LandingPage = () => {
  const [isPoweredOn, setIsPoweredOn] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [hoverTab, setHoverTab] = useState(null);
  const [currentMode, setCurrentMode] = useState('current');

  const skillCategories = [
    {
      icon: ShieldCheck,
      title: "Advanced Security",
      skills: ["Penetration Testing", "Network Security", "Security Onion", "Cybersecurity Defense", "Access Control Management"]
    },
    {
      icon: LockKeyhole,
      title: "Industry Knowledge",
      skills: ["Vulnerability Assessment", "Cyber Defense", "Compliance Management", "NIST 27001", "PCI-DSS Frameworks"]
    },
    {
      icon: Monitor,
      title: "Tools & Technology",
      skills: ["SIEM", "Firewalls", "IDS", "Qualys", "Nessus", "ServiceNow", "Active Directory", "VMware"]
    },
    {
      icon: Network,
      title: "Networking",
      skills: ["TCP/IP", "DNS", "DHCP", "VPN Configuration", "Network Troubleshooting"]
    },
    {
      icon: Wrench,
      title: "Hardware & Software",
      skills: ["Hardware Diagnostics", "Software Installation", "System Updates", "Remote Desktop"]
    },
    {
      icon: Database,
      title: "Security Fundamentals",
      skills: ["Firewalls", "Anti-virus Programs", "Network Security Practices", "Security Auditing"]
    },
    {
      icon: Code,
      title: "Programming",
      skills: ["Python", "Bash Scripting", "Automation"]
    },
    {
      icon: FileCode,
      title: "Certifications",
      skills: ["CompTIA Security+", "CompTIA A+", "CompTIA Network+", "Cisco CyberOps Associate (in progress)"]
    }
  ];

  const getBackgroundStyle = () => {
    if (!isPoweredOn) return '#080808';
    
    switch (currentMode) {
      case 'light':
        return '#f8fafc';
      case 'hacker':
        return '#001100';
      default:
        return 'linear-gradient(to bottom, #000000, #1a1a1a, #000000)';
    }
  };

  const getTextColorClass = (mode) => {
    switch (mode) {
      case 'light':
        return 'text-gray-800';
      case 'hacker':
        return 'text-green-500';
      default:
        return 'text-white';
    }
  };

  const getCardStyle = () => {
    switch (currentMode) {
      case 'light':
        return 'bg-white/80 border-gray-200/50 shadow-sm';
      case 'hacker':
        return 'bg-green-900/10 border-green-500/20';
      default:
        return 'bg-white/5 border-white/10';
    }
  };

  return (
    <div 
      className="min-h-screen w-full relative overflow-hidden mode-transition"
      style={{ background: getBackgroundStyle() }}
    >
      {isPoweredOn && (
        <>
          {currentMode === 'hacker' && <BinaryRain />}
          {currentMode !== 'light' && <DecorativeLines currentMode={currentMode} />}
        </>
      )}

      <NavMenu 
        isPoweredOn={isPoweredOn} 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
        hoverTab={hoverTab} 
        setHoverTab={setHoverTab}
        currentMode={currentMode}
        setMode={setCurrentMode}
      />
      
      {isPoweredOn && currentMode !== 'light' && (
        <VerticalNav currentMode={currentMode} />
      )}

      <section id="intro" className={`min-h-screen ${getTextColorClass(currentMode)}`}>
        <div className="pt-24 sm:pt-48 px-4 sm:px-8 md:px-12 lg:px-16">
          <div className="max-w-6xl mx-auto">
            {isPoweredOn && <TypingAnimation mode={currentMode} />}
          </div>
        </div>
      </section>

      <section id="about" className="min-h-screen pt-16 px-8 md:px-12 lg:px-16">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isPoweredOn ? 1 : 0, y: isPoweredOn ? 0 : 20 }}
            transition={{ duration: 0.8 }}
            className={`${getCardStyle()} backdrop-blur-lg rounded-2xl p-8 border mode-transition`}
          >
            <h2 className={`text-3xl font-mono mb-6 ${getTextColorClass(currentMode)}`}>
              About Me
            </h2>
            <div className="prose">
              <p className={`${getTextColorClass(currentMode)} opacity-80`}>
                Welcome! I'm a passionate IT Security/Cyber Analyst with a keen interest in protecting digital assets
                and maintaining cybersecurity integrity.
              </p>
              <p className={`${getTextColorClass(currentMode)} opacity-80 mt-4`}>
                I specialize in threat detection, incident response, and implementing robust security measures.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      <section id="skills" className="min-h-screen pt-16 px-8 md:px-12 lg:px-16">
  <div className="max-w-6xl mx-auto">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: isPoweredOn ? 1 : 0, y: isPoweredOn ? 0 : 20 }}
      transition={{ duration: 0.8 }}
      className={`${getCardStyle()} backdrop-blur-lg rounded-2xl p-8 border mode-transition`}
    >
      <h2 className={`text-3xl font-mono mb-6 ${getTextColorClass(currentMode)}`}>
        Skills & Expertise
      </h2>
      <div className="relative">
      <div className="overflow-x-auto hide-scrollbar show-scrollbar pb-4 -mx-2 px-2">
  <div className="flex flex-nowrap gap-6" style={{ scrollSnapType: 'x mandatory' }}>
    {skillCategories.map((category, index) => (
      <div 
        key={index} 
        className="flex-none w-[85vw] md:w-[400px]"
        style={{ scrollSnapAlign: 'start' }}
      >
        <SkillCard
          Icon={category.icon}
          title={category.title}
          skills={category.skills}
          currentMode={currentMode}
        />
      </div>
    ))}
  </div>
</div>
      </div>
    </motion.div>
  </div>
</section>

      <PowerToggle 
        isOn={isPoweredOn} 
        toggle={() => setIsPoweredOn(!isPoweredOn)} 
        currentMode={currentMode}
      />
    </div>
  );
};

export default LandingPage;
