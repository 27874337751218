import React from 'react';
import { motion } from 'framer-motion';
import { Power } from 'lucide-react';

const PowerToggle = ({ isOn, toggle, currentMode }) => {
  const getStyles = () => {
    if (!isOn) return {
      button: 'bg-black/80 border-gray-800',
      shadow: 'shadow-lg shadow-black/20',
      icon: 'text-gray-400'
    };
    
    switch (currentMode) {
      case 'light':
        return {
          button: 'bg-black border-gray-800',
          shadow: 'shadow-lg shadow-black/20',
          icon: 'text-white'
        };
      case 'hacker':
        return {
          button: 'bg-green-500 border-green-400',
          shadow: 'shadow-lg shadow-green-500/30',
          icon: 'text-black'
        };
      default:
        return {
          button: 'bg-white border-white/80',
          shadow: 'shadow-lg shadow-white/30',
          icon: 'text-black'
        };
    }
  };

  const styles = getStyles();

  return (
    <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50">
      <motion.button
        onClick={toggle}
        className={`w-14 h-14 rounded-full flex items-center justify-center transition-all duration-500 ease-out 
          backdrop-blur-xl border ${styles.button} ${styles.shadow}`}
        whileHover={{ scale: 1.05, y: -2 }}
        whileTap={{ scale: 0.98 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
      >
        <Power 
          className={`transition-all duration-500 ${styles.icon} ${isOn ? 'rotate-180' : 'rotate-0'}`} 
          size={18}
          strokeWidth={1.5}
        />
      </motion.button>
    </div>
  );
};

export default PowerToggle;