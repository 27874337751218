import React from 'react';
import { motion } from 'framer-motion';
import { Home, MessageCircle, Sun, Moon, Terminal } from 'lucide-react';
import GlitchText from './GlitchText';

const NavMenu = ({ isPoweredOn, currentMode, setMode, activeTab, setActiveTab, hoverTab, setHoverTab }) => {
  const navItems = [
    { id: 'home', icon: Home, label: 'Home' },
    { id: 'contact', icon: MessageCircle, label: 'Contact' }
  ];

  const modeIcons = {
    light: Sun,
    current: Moon,
    hacker: Terminal
  };

  return (
    <motion.nav
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: isPoweredOn ? 1 : 0, y: isPoweredOn ? 0 : -20 }}
      className="fixed top-0 left-0 right-0 z-50"
    >
      <div className={`px-4 py-2 ${
        currentMode === 'light' ? 'bg-white/80 shadow-lg shadow-gray-200/50 border-gray-300' : 
        currentMode === 'hacker' ? 'bg-black/60 shadow-lg shadow-green-500/10 border-green-500/30' : 
        'bg-black/60 shadow-lg shadow-blue-500/10 border-white/20'
      } backdrop-blur-xl border`}>
        <div className="max-w-7xl mx-auto flex flex-row items-center justify-between md:grid md:grid-cols-3 gap-2">
          {/* Logo/Brand */}
          <div className={`text-lg md:text-xl font-['Helvetica Now Display'] font-bold ${
            currentMode === 'light' ? 'text-gray-900' : 
            currentMode === 'hacker' ? 'text-green-500' : 'text-white'
          }`}>
            PORTFOLIO
          </div>

          {/* Main Navigation - Centered */}
          <div className="flex justify-center space-x-4 md:space-x-8">
            {navItems.map(({ id, icon: Icon, label }) => (
              <motion.div
                key={id}
                whileHover={isPoweredOn ? { scale: 1.1, y: -2 } : {}}
                className="relative"
                onHoverStart={() => isPoweredOn && setHoverTab(id)}
                onHoverEnd={() => isPoweredOn && setHoverTab(null)}
                onClick={() => isPoweredOn && setActiveTab(id)}
              >
                <motion.div className={`cursor-pointer px-2 md:px-4 py-1 md:py-2 rounded-xl flex items-center space-x-2
                  ${currentMode === 'light'
                    ? activeTab === id ? 'text-gray-800' : 'text-gray-600'
                    : currentMode === 'hacker'
                    ? activeTab === id ? 'text-green-400' : 'text-green-600'
                    : activeTab === id ? 'text-white' : 'text-white/70'
                  }`}>
                  <Icon size={14} />
                  <span className="hidden md:inline font-['Helvetica Now Display'] font-bold">
                    <GlitchText text={label} isHovered={isPoweredOn && hoverTab === id} mode={currentMode} />
                  </span>
                </motion.div>
              </motion.div>
            ))}
          </div>

          {/* Mode Toggles */}
          <div className="flex justify-end space-x-1 md:space-x-2">
            {Object.entries(modeIcons).map(([mode, Icon]) => (
              <motion.button
                key={mode}
                onClick={() => setMode(mode)}
                whileHover={{ scale: 1.1 }}
                className={`p-1.5 md:p-2 rounded-full transition-all ${
                  currentMode === mode
                    ? mode === 'light' ? 'bg-gray-200 text-gray-900'
                    : mode === 'hacker' ? 'bg-green-500/20 text-green-400'
                    : 'bg-blue-500/20 text-blue-400'
                  : 'text-gray-400'
                }`}
              >
                <Icon size={14} className="md:w-4 md:h-4" />
              </motion.button>
            ))}
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default NavMenu;
