import React from 'react';
import { motion } from 'framer-motion';

const getSkillStyle = (currentMode) => {
  switch (currentMode) {
    case 'light':
      return {
        container: 'bg-white/80 md:bg-white/5 border-gray-200/10',
        title: 'text-gray-900',
        icon: 'text-gray-800',
        skillBg: 'bg-gray-100',
        skillText: 'text-gray-800'
      };
    case 'hacker':
      return {
        container: 'bg-green-950/80 md:bg-green-950/5 border-green-500/10',
        title: 'text-green-500',
        icon: 'text-green-400',
        skillBg: 'bg-green-500/20',
        skillText: 'text-green-400'
      };
    default:
      return {
        container: 'bg-black/80 md:bg-white/5 border-white/10',
        title: 'text-white',
        icon: 'text-white/90',
        skillBg: 'bg-white/10',
        skillText: 'text-white/80'
      };
  }
};

const SkillCard = ({ Icon, title, skills, currentMode }) => {
  const styles = getSkillStyle(currentMode);

  return (
    <div
      className={`relative rounded-2xl border p-6 min-h-[420px] transition-all duration-300
        ${styles.container} hover:border-white/20 backdrop-blur-sm md:backdrop-blur-md`}
    >
      {/* Top section with icon and title */}
      <div className="flex items-center space-x-3 mb-6">
        <div className={`${styles.icon}`}>
          <Icon size={24} strokeWidth={1.5} />
        </div>
        <h3 className={`text-xl font-light tracking-wide ${styles.title}`}>
          {title}
        </h3>
      </div>

      {/* Skills grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {skills.map((skill, index) => (
          <div
            key={index}
            className={`${styles.skillBg} ${styles.skillText} 
              px-3 py-2 rounded-lg text-sm font-light tracking-wide
              transition-colors duration-300 hover:bg-white/20`}
          >
            {skill}
          </div>
        ))}
      </div>

      {/* Tesla-inspired hover effect - only show on desktop */}
      <div 
        className="absolute inset-0 rounded-2xl opacity-0 group-hover:opacity-100 
          transition-opacity duration-300 pointer-events-none hidden md:block"
        style={{
          background: `radial-gradient(
            800px circle at var(--mouse-x, 0) var(--mouse-y, 0),
            ${currentMode === 'hacker' ? 'rgba(0, 255, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)'} 0%,
            transparent 40%
          )`
        }}
      />
    </div>
  );
};

// Add mouse move effect only on desktop
if (typeof document !== 'undefined' && window.innerWidth >= 768) {
  document.addEventListener('mousemove', (e) => {
    document.querySelectorAll('.group').forEach(card => {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      card.style.setProperty('--mouse-x', `${x}px`);
      card.style.setProperty('--mouse-y', `${y}px`);
    });
  });
}

export default SkillCard;